<template>
  <BaseIcon2
    ref="loadingElement"
    class="base-loader"
    :class="className"
    :height="size"
    viewBox="0 0 100 100"
    :width="size"
  >
    <g transform="translate(50 50)">
      <g
        v-for="(transform, transformIndex) in CIRCLE_TRANSFORMS"
        :key="transformIndex"
        :transform
      >
        <circle
          cx="0"
          cy="0"
          r="2"
        />
      </g>
    </g>
  </BaseIcon2>
</template>

<script lang="ts" setup>
import type { SVGAttributes } from "vue";

interface Props extends /* @vue-ignore */ SVGAttributes {
  class?: string;
  size?: number | "100%";
}

const props = withDefaults(defineProps<Props>(), {
  size: "100%",
});

const { class: className, size } = toRefs(props);
const loadingElement = ref<SVGElement | null>(null);

const CIRCLE_TRANSFORMS = [
  "rotate(0) translate(34 0)",
  "rotate(45) translate(34 0)",
  "rotate(90) translate(34 0)",
  "rotate(135) translate(34 0)",
  "rotate(180) translate(34 0)",
  "rotate(225) translate(34 0)",
  "rotate(270) translate(34 0)",
  "rotate(315) translate(34 0)",
];

defineExpose({
  loadingElement,
});
</script>

<style scoped>
@keyframes spin {
  0% {
    scale: 1;
  }

  50% {
    scale: 4;
  }

  100% {
    scale: 1;
  }
}

circle {
  animation: spin 1s infinite;
}

g:nth-child(1) circle {
  animation-delay: 0s;
}

g:nth-child(2) circle {
  animation-delay: calc(1 / 8 * 1s);
}

g:nth-child(3) circle {
  animation-delay: calc(2 / 8 * 1s);
}

g:nth-child(4) circle {
  animation-delay: calc(3 / 8 * 1s);
}

g:nth-child(5) circle {
  animation-delay: calc(4 / 8 * 1s);
}

g:nth-child(6) circle {
  animation-delay: calc(5 / 8 * 1s);
}

g:nth-child(7) circle {
  animation-delay: calc(6 / 8 * 1s);
}

g:nth-child(8) circle {
  animation-delay: calc(7 / 8 * 1s);
}
</style>
