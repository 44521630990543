const pageScrollBlock = () => {
  const currentScrollPosition = window.scrollY;
  const scrollbarWidth = window.innerWidth - document.documentElement.clientWidth;

  document.documentElement.style.cssText = `
      position: fixed;
      top: -${currentScrollPosition}px;
      right: 0;
      left: 0;
      margin-right: ${scrollbarWidth}px;
      overflow-y: hidden;
    `;
};

const pageScrollComputedWidth = () => {
  const scrollbarWidth = window.innerWidth - document.documentElement.clientWidth;

  document.body.style.setProperty("--scrollbar-width", `${scrollbarWidth}px`);
};

const pageScrollUnblock = () => {
  const currentScrollPosition = Number(document.documentElement.style.top.replace("px", "")) * -1;

  document.documentElement.removeAttribute("style");
  window.scrollTo(0, currentScrollPosition);
};

export { pageScrollBlock, pageScrollComputedWidth, pageScrollUnblock };
