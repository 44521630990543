import type { NitroFetchOptions, NitroFetchRequest } from "nitropack";

const fetchRequest = async <T>(endpoint: string, options: NitroFetchOptions<NitroFetchRequest> = {}) => {
  const defaultOptions: NitroFetchOptions<NitroFetchRequest> = {
    retry: 5,
  };

  const allOptions = mergeData(defaultOptions, options);

  try {
    const { _data, headers, ok, status } = await $fetch.raw<T>(endpoint, allOptions);

    return {
      body: _data ?? ({} as T),
      headers,
      status,
      success: ok,
    };
  } catch (error) {
    return {
      body: error.data as T,
      headers: {},
      status: 500,
      success: false,
    };
  }
};

export { fetchRequest };
