enum SourceType {
  ARRAY = "array",
  DATE = "date",
  MAP = "map",
  NOT = "not",
  OBJECT = "record",
  OTHER = "other",
  REGEXP = "regexp",
  SET = "set",
}

const isObject = (value: object): value is Record<PropertyKey, unknown> => {
  const validObjectToStringValues = new Set(["[object Object]", "[object Module]"]);

  if (!validObjectToStringValues.has(Object.prototype.toString.call(value))) {
    return false;
  }

  const { constructor } = value;

  // eslint-disable-next-line no-undefined -- Проверяем на изменённый конструктор
  if (constructor === undefined) {
    return true;
  }

  // eslint-disable-next-line prefer-destructuring -- Необходимо присвоить тип
  const prototype: unknown = constructor.prototype;

  if (
    prototype === null ||
    typeof prototype !== "object" ||
    !validObjectToStringValues.has(Object.prototype.toString.call(prototype))
  ) {
    return false;
  }

  return Object.hasOwn(prototype, "isPrototypeOf");
};

const getSourceType = (source: unknown): SourceType => {
  if (typeof source !== "object" || source === null) {
    return SourceType.NOT;
  }

  if (Array.isArray(source)) {
    return SourceType.ARRAY;
  }

  if (isObject(source)) {
    return SourceType.OBJECT;
  }

  if (source instanceof Date) {
    return SourceType.SET;
  }

  if (source instanceof Set) {
    return SourceType.SET;
  }

  if (source instanceof Map) {
    return SourceType.MAP;
  }

  if (source instanceof RegExp) {
    return SourceType.REGEXP;
  }

  return SourceType.OTHER;
};

const setProperty = <T>(object: T, key: string | symbol, source: T, value?: PropertyDescriptor) => {
  if (value?.value instanceof Object) {
    // eslint-disable-next-line @typescript-eslint/no-use-before-define -- Необходима рекурсия
    value.value = copyData(value.value as T[Extract<keyof T, string>]);
  }

  if (key === "__proto__" || key === "constructor") {
    Object.defineProperty(object, key, {
      configurable: true,
      enumerable: true,
      // eslint-disable-next-line @typescript-eslint/no-use-before-define -- Необходима рекурсия
      value: copyData(source[key as keyof T]) as T,
      writable: true,
    });
  } else {
    object[key as keyof T] = value?.value as T[Extract<keyof T, string>];
  }
};

const copy = <T>(newData: T, source: T) => {
  for (let index = 0, list = Object.getOwnPropertySymbols(source); index < list.length; index++) {
    setProperty(newData, list[index], source, Object.getOwnPropertyDescriptor(source, list[index]));
  }

  for (let index = 0, list = Object.getOwnPropertyNames(source); index < list.length; index++) {
    setProperty(newData, list[index], source, Object.getOwnPropertyDescriptor(source, list[index]));
  }

  return newData;
};

const copyData = <T>(source: T) => {
  const type = getSourceType(source);

  switch (type) {
    case SourceType.OBJECT: {
      const prototype = Object.getPrototypeOf(source) as object | null;
      const newObject = Object.create(prototype) as T;

      return copy(newObject, source);
    }

    case SourceType.ARRAY: {
      const newArray = Array.from({ length: (source as T[]).length }) as T;

      return copy(newArray, source);
    }

    case SourceType.SET: {
      const newSet = new Set<T[keyof T]>();

      (source as Set<T[keyof T]>).forEach((value: T[keyof T]) => newSet.add(copyData(value)));

      return copy(newSet as T, source);
    }

    case SourceType.MAP: {
      const newMap = new Map<keyof T, T[keyof T]>();

      (source as Map<keyof T, T[keyof T]>).forEach((value: T[keyof T], key: keyof T) => {
        return newMap.set(copyData(key), copyData(value));
      });

      return copy(newMap as T, source);
    }

    case SourceType.DATE: {
      return new Date(Number(source)) as T;
    }

    case SourceType.REGEXP: {
      return new RegExp((source as RegExp).source, (source as RegExp).flags) as T;
    }

    default: {
      return source;
    }
  }
};

export { copyData };
