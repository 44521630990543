<template>
  <Html lang="ru">
    <Head>
      <Link
        v-if="$env.isProd"
        as="font"
        crossorigin="anonymous"
        href="/fonts/commissioner/medium.woff2"
        rel="preload"
        type="font/woff2"
      />

      <Link
        v-if="$env.isProd"
        as="font"
        crossorigin="anonymous"
        href="/fonts/commissioner/regular.woff2"
        rel="preload"
        type="font/woff2"
      />

      <Link
        href="/manifest.webmanifest"
        rel="manifest"
      />

      <Link
        href="/favicon.ico"
        rel="icon"
      />

      <Link
        href="/favicon-16x16.png"
        rel="icon"
        sizes="16x16"
        type="image/png"
      />

      <Link
        href="/favicon-32x32.png"
        rel="icon"
        sizes="32x32"
        type="image/png"
      />

      <Link
        href="/icon.svg"
        rel="icon"
        type="image/svg+xml"
      />

      <Link
        href="/apple-touch-icon.png"
        rel="apple-touch-icon"
      />

      <Meta
        content="initial-scale=1.0, width=device-width"
        name="viewport"
      />
    </Head>
  </Html>

  <BaseLoadingProgress2 class="base-loading-progress-first" />

  <NuxtLayout>
    <NuxtPage />
  </NuxtLayout>

  <BaseNotification />
</template>

<script lang="ts" setup>
const { appTitle } = useAppConfig();
const { $env } = useNuxtApp();

useSeoMeta({
  titleTemplate: (pageTitle) => {
    return typeof pageTitle === "string" && !isEmpty(pageTitle) ? `${pageTitle} | ${appTitle}` : appTitle;
  },
});

onMounted(() => {
  pageScrollComputedWidth();
  useAuthSocialError();
  useErrorForNotification();
});
</script>
