type MergeDataAnd<T1 extends boolean, T2 extends boolean> = T1 extends false ? false : T2;

type MergeDataNot<T extends boolean> = T extends true ? false : true;

type MergeDataIs<T1, T2> = [T1] extends [T2] ? true : false;

type MergeDataIsArray<T> = MergeDataAnd<MergeDataNot<MergeDataIsNever<T>>, T extends readonly unknown[] ? true : false>;

type MergeDataIsMap<T> = MergeDataAnd<
  MergeDataNot<MergeDataIsNever<T>>,
  T extends Readonly<ReadonlyMap<unknown, unknown>> ? true : false
>;

type MergeDataIsNever<T> = MergeDataIs<T, never>;

type MergeDataIsObject<T> = MergeDataAnd<
  MergeDataNot<MergeDataIsNever<T>>,
  T extends Readonly<Record<PropertyKey, unknown>> ? true : false
>;

type MergeDataIsSet<T> = MergeDataAnd<
  MergeDataNot<MergeDataIsNever<T>>,
  T extends Readonly<ReadonlySet<unknown>> ? true : false
>;

type MergeDataIsTuple<T extends readonly unknown[]> = T extends readonly []
  ? true
  : T extends readonly [unknown, ...(readonly unknown[])]
  ? true
  : false;

type MergeDataEveryIsArray<T extends readonly unknown[]> = T extends readonly [infer T1]
  ? MergeDataIsArray<T1>
  : T extends readonly [infer Head, ...infer Rest]
  ? MergeDataIsArray<Head> extends true
    ? Rest extends readonly [unknown, ...(readonly unknown[])]
      ? MergeDataEveryIsArray<Rest>
      : false
    : false
  : false;

type MergeDataEveryIsMap<T extends readonly unknown[]> = T extends Readonly<readonly [infer T1]>
  ? MergeDataIsMap<T1>
  : T extends readonly [infer Head, ...infer Rest]
  ? MergeDataIsMap<Head> extends true
    ? Rest extends readonly [unknown, ...(readonly unknown[])]
      ? MergeDataEveryIsMap<Rest>
      : false
    : false
  : false;

type MergeDataEveryIsObject<T extends readonly unknown[]> = T extends readonly [infer Head, ...infer Rest]
  ? MergeDataIsObject<Head> extends true
    ? Rest extends readonly unknown[]
      ? MergeDataEveryIsObject<Rest>
      : true
    : false
  : true;

type MergeDataEveryIsSet<T extends readonly unknown[]> = T extends Readonly<readonly [infer T1]>
  ? MergeDataIsSet<T1>
  : T extends readonly [infer Head, ...infer Rest]
  ? MergeDataIsSet<Head> extends true
    ? Rest extends readonly [unknown, ...(readonly unknown[])]
      ? MergeDataEveryIsSet<Rest>
      : false
    : false
  : false;

declare const MergeDataEmptyObjectSymbol: unique symbol;

interface MergeDataEmptyObject {
  [MergeDataEmptyObjectSymbol]?: never;
}

type MergeDataKeysOfType<T, U> = {
  [K in keyof T]: T[K] extends U ? K : never;
}[keyof T];

type MergeDataFlatterAlias<T> = MergeDataIs<T, unknown> extends true
  ? T
  : MergeDataEmptyObject & { [P in keyof T]: T[P] };

type MergeDataRequiredKeys<T> = Exclude<MergeDataKeysOfType<T, Exclude<T[keyof T], undefined>>, undefined>;

type MergeDataRequiredKeysOf<T extends readonly [unknown, ...(readonly unknown[])], Accumulator> = T extends readonly [
  infer Head,
  ...infer Rest,
]
  ? Head extends Record<PropertyKey, unknown>
    ? Rest extends readonly [unknown, ...(readonly unknown[])]
      ? MergeDataRequiredKeysOf<Rest, Accumulator | MergeDataRequiredKeys<Head>>
      : Accumulator | MergeDataRequiredKeys<Head>
    : never
  : Accumulator;

type MergeDataOptionalKeys<T> = Exclude<keyof T, MergeDataRequiredKeys<T>>;

type MergeDataOptionalKeysOf<T extends readonly [unknown, ...(readonly unknown[])], Accumulator> = T extends readonly [
  infer Head,
  ...infer Rest,
]
  ? Head extends Record<PropertyKey, unknown>
    ? Rest extends readonly [unknown, ...(readonly unknown[])]
      ? MergeDataOptionalKeysOf<Rest, Accumulator | MergeDataOptionalKeys<Head>>
      : Accumulator | MergeDataOptionalKeys<Head>
    : never
  : Accumulator;

type MergeDataFilterOutNever<
  T extends readonly unknown[],
  Accumulator extends readonly unknown[],
> = T extends readonly []
  ? Accumulator
  : T extends readonly [infer Head, ...infer Rest]
  ? MergeDataIsNever<Head> extends true
    ? MergeDataFilterOutNever<Rest, Accumulator>
    : MergeDataFilterOutNever<Rest, [...Accumulator, Head]>
  : T;

type MergeDataValueOfKey<T extends Record<PropertyKey, unknown>, K extends PropertyKey> = K extends keyof T
  ? T[K]
  : never;

type MergeDataArrays<T extends readonly unknown[], Accumulator extends readonly unknown[]> = T extends readonly [
  infer Head extends readonly unknown[],
  ...infer Rest,
]
  ? Rest extends readonly [readonly unknown[], ...(readonly (readonly unknown[])[])]
    ? MergeDataArrays<Rest, [...Accumulator, ...Head]>
    : [...Accumulator, ...Head]
  : never;

type MergeDataUnionMapKeys<T extends readonly unknown[], Accumulator> = T extends readonly [infer Head, ...infer Rest]
  ? Head extends Readonly<ReadonlyMap<infer K1, unknown>>
    ? Rest extends readonly []
      ? Accumulator | K1
      : MergeDataUnionMapKeys<Rest, Accumulator | K1>
    : never
  : Accumulator;

type MergeDataUnionMapValues<T extends readonly unknown[], Accumulator> = T extends readonly [infer Head, ...infer Rest]
  ? Head extends Readonly<ReadonlyMap<unknown, infer V1>>
    ? Rest extends readonly []
      ? Accumulator | V1
      : MergeDataUnionMapValues<Rest, Accumulator | V1>
    : never
  : Accumulator;

type MergeDataMaps<T extends readonly unknown[]> = Map<
  MergeDataUnionMapKeys<T, never>,
  MergeDataUnionMapValues<T, never>
>;

type MergeDataObjectInternalPropertyValueHelper<
  T extends readonly [unknown, ...(readonly unknown[])],
  K extends PropertyKey,
  Accumulator extends readonly unknown[],
> = T extends readonly [infer Head extends Readonly<Record<PropertyKey, unknown>>, ...infer Rest]
  ? Rest extends readonly [unknown, ...(readonly unknown[])]
    ? MergeDataObjectInternalPropertyValueHelper<Rest, K, [...Accumulator, MergeDataValueOfKey<Head, K>]>
    : [...Accumulator, MergeDataValueOfKey<Head, K>]
  : never;

type MergeDataObjectInternalPropertyValue<
  T extends readonly [unknown, ...(readonly unknown[])],
  K extends PropertyKey,
> = MergeDataFilterOutNever<MergeDataObjectInternalPropertyValueHelper<T, K, readonly []>, []>;

type MergeDataObjectInternalProps<T extends readonly [unknown, ...(readonly unknown[])]> = {
  [K in MergeDataOptionalKeysOf<T, never>]?: MergeData<MergeDataObjectInternalPropertyValue<T, K>>;
} & {
  [K in MergeDataRequiredKeysOf<T, never>]: MergeData<MergeDataObjectInternalPropertyValue<T, K>>;
};

type MergeDataObject<T extends readonly unknown[]> = T extends Readonly<
  readonly [unknown, ...Readonly<readonly unknown[]>]
>
  ? MergeDataFlatterAlias<Omit<MergeDataObjectInternalProps<T>, "__proto__">>
  : MergeDataEmptyObject;

type MergeDataUnionSetValues<T extends readonly unknown[], Accumulator> = T extends readonly [infer Head, ...infer Rest]
  ? Head extends Readonly<ReadonlySet<infer V1>>
    ? Rest extends readonly unknown[]
      ? MergeDataUnionSetValues<Rest, Accumulator | V1>
      : Accumulator | V1
    : never
  : Accumulator;

type MergeDataSets<T extends readonly unknown[]> = Set<MergeDataUnionSetValues<T, never>>;

type MergeDataLeaf<T extends readonly unknown[]> = T extends readonly []
  ? never
  : T extends readonly [infer U]
  ? U
  : T extends readonly [...infer Rest, infer Tail]
  ? MergeDataIsNever<Tail> extends true
    ? Rest extends readonly unknown[]
      ? MergeDataLeaf<Rest>
      : never
    : Tail
  : never;

type MergeData<T extends readonly unknown[]> = MergeDataIsTuple<T> extends true
  ? T extends readonly []
    ? undefined
    : T extends readonly [infer T1]
    ? T1
    : MergeDataEveryIsArray<T> extends true
    ? MergeDataArrays<T, []>
    : MergeDataEveryIsMap<T> extends true
    ? MergeDataMaps<T>
    : MergeDataEveryIsObject<T> extends true
    ? MergeDataObject<T>
    : MergeDataEveryIsSet<T> extends true
    ? MergeDataSets<T>
    : MergeDataLeaf<T>
  : unknown;

enum SourceType {
  ARRAY = "array",
  MAP = "map",
  NOT = "not",
  OBJECT = "record",
  OTHER = "other",
  SET = "set",
}

const isObject = (value: object): value is Record<PropertyKey, unknown> => {
  const validObjectToStringValues = new Set(["[object Object]", "[object Module]"]);

  if (!validObjectToStringValues.has(Object.prototype.toString.call(value))) {
    return false;
  }

  const { constructor } = value;

  // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition, no-undefined -- Проверяем на изменённый конструктор
  if (constructor === undefined) {
    return true;
  }

  // eslint-disable-next-line prefer-destructuring -- Необходимо присводить тип
  const prototype: unknown = constructor.prototype;

  if (
    prototype === null ||
    typeof prototype !== "object" ||
    !validObjectToStringValues.has(Object.prototype.toString.call(prototype))
  ) {
    return false;
  }

  return Object.hasOwn(prototype, "isPrototypeOf");
};

const getSourceType = (source: unknown): SourceType => {
  if (typeof source !== "object" || source === null) {
    return SourceType.NOT;
  }

  if (Array.isArray(source)) {
    return SourceType.ARRAY;
  }

  if (isObject(source)) {
    return SourceType.OBJECT;
  }

  if (source instanceof Set) {
    return SourceType.SET;
  }

  if (source instanceof Map) {
    return SourceType.MAP;
  }

  return SourceType.OTHER;
};

const getObjectKeys = (objects: readonly object[]): Set<PropertyKey> => {
  const keys = new Set<PropertyKey>();

  for (const object of objects) {
    for (const key of [...Object.keys(object), ...Object.getOwnPropertySymbols(object)]) {
      keys.add(key);
    }
  }

  return keys;
};

const hasObjectProperty = (object: object, property: PropertyKey): boolean => {
  return typeof object === "object" && Object.prototype.propertyIsEnumerable.call(object, property);
};

const getIterableOfIterables = <T>(iterables: readonly Readonly<Iterable<T>>[]): Iterable<T> => {
  return {
    *[Symbol.iterator]() {
      for (const iterable of iterables) {
        for (const value of iterable) {
          yield value;
        }
      }
    },
  };
};

const mergeOthers = (values: readonly unknown[]) => {
  return values.at(-1);
};

const mergeObjects = <T extends readonly Record<PropertyKey, unknown>[]>(values: T): MergeData<T> => {
  const result: Record<PropertyKey, unknown> = {};

  for (const key of getObjectKeys(values)) {
    const propertyValues = [];

    for (const value of values) {
      if (hasObjectProperty(value, key)) {
        propertyValues.push(value[key]);
      }
    }

    if (propertyValues.length === 0) {
      continue;
    }

    // eslint-disable-next-line @typescript-eslint/no-use-before-define -- Необходима рекурсия
    const propertyResult = mergeUnknowns<readonly unknown[]>(propertyValues);

    if (key === "__proto__") {
      Object.defineProperty(result, key, {
        configurable: true,
        enumerable: true,
        value: propertyResult,
        writable: true,
      });
    } else {
      result[key] = propertyResult;
    }
  }

  return result as MergeData<T>;
};

const mergeArrays = <T extends readonly (readonly unknown[])[]>(values: T): MergeData<T> => {
  return values.flat() as MergeData<T>;
};

const mergeSets = <T extends readonly Readonly<ReadonlySet<unknown>>[]>(values: T): MergeData<T> => {
  return new Set(getIterableOfIterables(values)) as MergeData<T>;
};

const mergeMaps = <T extends readonly Readonly<ReadonlyMap<unknown, unknown>>[]>(values: T): MergeData<T> => {
  return new Map(getIterableOfIterables(values)) as MergeData<T>;
};

const mergeUnknowns = <T extends readonly unknown[]>(values: T) => {
  if (values.length === 0) {
    // eslint-disable-next-line no-undefined -- Необходимо вернуть пустое значение
    return undefined as MergeData<T>;
  }

  if (values.length === 1) {
    return mergeOthers(values);
  }

  const type = getSourceType(values[0]);

  if (type !== SourceType.NOT && type !== SourceType.OTHER) {
    for (let index = 1; index < values.length; index++) {
      if (getSourceType(values[index]) === type) {
        continue;
      }

      return mergeOthers(values);
    }
  }

  switch (type) {
    case SourceType.OBJECT: {
      return mergeObjects(values as readonly Readonly<Record<PropertyKey, unknown>>[]);
    }

    case SourceType.ARRAY: {
      return mergeArrays(values as readonly Readonly<readonly unknown[]>[]);
    }

    case SourceType.SET: {
      return mergeSets(values as readonly Readonly<ReadonlySet<unknown>>[]);
    }

    case SourceType.MAP: {
      return mergeMaps(values as readonly Readonly<ReadonlyMap<unknown, unknown>>[]);
    }

    default: {
      return mergeOthers(values);
    }
  }
};

const mergeData = <T extends Readonly<readonly unknown[]>>(...values: readonly [...T]): MergeData<T> => {
  return mergeUnknowns(values) as MergeData<T>;
};

export { mergeData };
