type AppName = "client-view" | "crm" | "main";

const useAppLink = () => {
  const { $env } = useNuxtApp();
  const appHostname = $env.isDev ? "mydonut.local" : "mydonut.app";
  const appProtocol = $env.isDev ? "http://" : "https://";

  const getAppPort = (appName: AppName) => {
    if (!$env.isDev) {
      return "";
    }

    switch (appName) {
      case "client-view": {
        return ":3003";
      }

      case "crm": {
        return ":3002";
      }

      case "main": {
        return ":3001";
      }

      // No default
    }
  };

  const createAppLink = (link: string, appName: AppName, domain = "") => {
    const port = getAppPort(appName);

    return `${appProtocol}${domain ? `${domain}.` : ""}${appHostname}${port}${link}`;
  };

  return {
    appHostname,
    createAppLink,
  };
};

export { useAppLink };
